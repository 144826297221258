import React from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import EmailForm from './../emailForm/emailForm';

const LocationsAndHours = () => {
return (
<MDBContainer >
<MDBCol md="6" className="emailFormInLocationsPg"><EmailForm/><br/><br/></MDBCol>
   <MDBRow className="officeLocationsAndHours">

    <MDBCol md="3" className="officeLocationsTitle">OFFICE LOCATION</MDBCol>
    {/* <MDBCol md="9" ></MDBCol>
    <MDBCol md="3" className="addresses">Alpine Oak Dental</MDBCol> */}
    <MDBCol md="9" ><br/>2806 Alpine Boulevard<br/>Suite C<br/>Alpine, CA 91901<br/> {/*<br/><a href="tel:(619)-659-9580">(619)-659-9580</a><br/><br/>*/}</MDBCol>
    <MDBCol md="3" className="officePhoneTitle">PHONE</MDBCol>
    <MDBCol md="9" ><a href="tel:(619)-659-9580">(619)-659-9580</a></MDBCol>
    <MDBCol md="3" className="faxTitle">FAX</MDBCol>
    <MDBCol md="9"><a href="tel:(760)-440-9555">(760)-440-9555</a></MDBCol>
    {/*<MDBCol md="3">Ramona Oak Dental</MDBCol>*/}
    {/*<MDBCol md="9">1721 Main St #102,<br/>Ramona, CA 92065<br/><br/><a href="tel:(760-440-9000)">(760)-440-9000</a><br/><br/></MDBCol>*/}
  


    <MDBCol md="3" className="weeklyScheduleTitle">WEEKLY SCHEDULE</MDBCol>
    <MDBCol md="9" ><br/><br/></MDBCol>
    <MDBCol md="3" className="dayOfWeek">Monday</MDBCol>
    <MDBCol md="9">{/*Alpine: */}9am - 5pm{/* <br/>Ramona: by appointment<br/>*/}<br/></MDBCol>
    
    <MDBCol md="3" className="dayOfWeek">Tuesday</MDBCol>
    <MDBCol md="9">{/*Alpine: */}11am - 7pm{/* <br/>Ramona: 9am-5pm<br/><br/>*/}</MDBCol>
    <MDBCol md="3" className="dayOfWeek">Wednesday</MDBCol>
    <MDBCol md="9">{/*Alpine: */}9am - 5pm{/* <br/>Ramona: by appointment<br/>*/}<br/></MDBCol>
    <MDBCol md="3" className="dayOfWeek">Thursday</MDBCol>
    <MDBCol md="9">{/*Alpine: */}9am - 5pm{/* <br/>Ramona: 9am-5pm<br/>*/}<br/></MDBCol>
    <MDBCol md="3" className="dayOfWeek">Friday</MDBCol>
    <MDBCol md="9">{/*Alpine: */}By appointment only{/* <br/>Ramona: by appointment<br/>*/}<br/></MDBCol>
   
  </MDBRow>
  
</MDBContainer>

);
}

export default LocationsAndHours;