

import React from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import { MDBBtn } from "mdbreact";
import {Link} from 'react-router-dom';
import pict1 from '../../images/book-4600757__480.webp';
import pict2 from '../../images/oak-354641__480.jpg';
import pict3 from '../../images/dentist-1437430__480.webp';


const TripleCardFinalY = () => {
return (
<MDBContainer className="tripleCardContainerFinalY">
  <MDBRow>
    <MDBCol md="4" className="YorgiCards">
        <Link to="/meetTheDoctor" className="tripleCardLinkY">
        <div className="cardItemY">
            <div className="cardContentY">
                <div className="cardContentTopY">
                    <img className="tripleCardImgY img-fluid" src={pict1}></img>
                </div>
                <div className="cardContentBottomY">
                    <h6 className="cardTitleY">MEET THE DOCTOR</h6>
                    <MDBBtn color="info">Learn More</MDBBtn>
                    <p className="cardTextY">Voted "Top Doc" by 500 other doctors in the LA region, find out more about why Dr. Orozco is the one patients and practitioners look to for advice. Always looking to learn, Dr. Orozco has mastered general dentistry, orthodontics, and multiple oral surgeries.</p>
                  
                        
                   
                   
                </div>
                
                
            </div>
            
        </div>
        </Link>
        
        

    </MDBCol>
    <MDBCol md="4" className="YorgiCards">
        <Link to="/locationsAndHours" className="tripleCardLinkY">
        <div className="cardItemY">
            <div className="cardContentY">
                <div className="cardContentTopY">
                    <img className="tripleCardImgY" src={pict2}></img>
                </div>
                <div className="cardContentBottomY">
                    <h6 className="cardTitleY">HOURS AND LOCATION</h6>
                    <MDBBtn color="info">Learn More</MDBBtn>
                    <p className="cardTextY">
                    <p class="officeTitle">ALPINE OAK DENTAL</p>
              <p>
              2806 Alpine Boulevard,<br/>Suite C<br/> Alpine, CA 91901<br/>
              <p>
              <a href="tel:(619)-659-9580">(619)-659-9580</a><br/>
              </p>
              <p>
            
            Mon: 9am - 5pm<br/>
            Tues: 11am - 7pm<br/>
            Wed: 9am - 5pm<br/>
            Thur: 9am - 5pm<br/>
            Fri: by appointment only
          </p> 
            </p>

         
              {/*<p class="officeTitle">RAMONA OAK DENTAL</p>*/}
              {/*1721 Main St #102,<br/> Ramona, CA 92065*/}
              {/*</p>*/}
              {/*<p>*/}
                
              {/*  Mon: by appointment<br/>*/}
              {/*  Tues: 9 am - 5 pm<br/>*/}
              {/*  Wed: by appointment<br/>*/}
              {/*  Thur: 9 am - 5 pm<br/>*/}
              {/*  Fri: by appointment*/}
              {/*</p>       */}
                        
                    </p>
                  
                      
                   
                   
                </div>
                
                
            </div>
            
        </div>
        </Link>
        
        

    </MDBCol>
    <MDBCol md="4" className="YorgiCards">
        <Link to="/services" className="tripleCardLinkY">
        <div className="cardItemY">
            <div className="cardContentY">
                <div className="cardContentTopY">
                    <img className="tripleCardImgY" md="4" src={pict3}></img>
                </div>
                <div className="cardContentBottomY">
            
                    <h6 className="cardTitleY">SERVICES</h6>
                    <MDBBtn color="info">Learn More</MDBBtn>
                    <p className="cardTextY">
                   
            
            Hygiene<br/><br/>
            Cosmetic and General Dentistry<br/><br/>
            Endodontics<br/><br/>
            Oral Surgery<br/><br/>
            Orthodontics<br/><br/>
            Serving patients age 3 to 103
          
         
                    </p>
                  
                        
                   
                   
                </div>
                
                
            </div>
            
        </div>
        </Link>
        
        

    </MDBCol>
   
  </MDBRow>
</MDBContainer>
);
}

export default TripleCardFinalY;